<template lang="pug">
.dashboard
  .mt-2.grid.grid-cols-1.gap-12( v-if="aggregates.Receipts && !isPending && !expensesPending" )
    div( class="cc-card pa-6 hover:shadow-lg" )
      h1.mt-4(
        :class="balance > 0 ? 'text-green-700' : 'text-red-700'"
      ) {{ balance | toCurrency }}
      .font-semibold.text-gray-500.uppercase.text-xs.pt-2 BALANCE

      .d-flex.justify-between.gap-16.mt-12.border-t
        div( class="flex-1")
          .d-flex.justify-between.pt-8
            div
              h3.text-green-700 {{ aggregates.Receipts.sum.formatted }}
              .font-semibold.text-gray-500.uppercase.text-xs.pt-2 Receipts Total

            div
              v-icon( large ) mdi-hand-extended-outline

          .d-flex.justify-between
            .font-semibold.text-gray-500.uppercase.text-xs.mt-12 Top 3 Donors
            .font-semibold.text-gray-500.uppercase.text-xs.mt-12 Amount
          v-divider.mt-4

          v-simple-table( v-if="!receiptsPending" )
            template( v-slot:default )
              tbody
                tr(
                  v-for="receipt in top3Receipts"
                )
                  td( class="w-auto lg:w-1/3 pl-0" ) {{ receipt.firstName + ' ' + receipt.lastName }}
                  td( class="w-auto text-right border-r" )
                    span.text-gray-500 {{ $day(receipt.invoiceDate).format('MMM Do') }}
                  td( class="w-auto lg:w-1/6 text-right" )
                    | {{ receipt.amount | toCurrency }}
                tr(
                  v-if="top3Receipts.length === 0"
                )
                  td
                    .text-center.text-gray-500.py-4 No Receipts

        v-divider( vertical )

        div( class="flex-1")
          .d-flex.justify-between.pt-8
            div
              h3.text-red-800 {{ aggregates.Expenses.sum.formatted }}
              .font-semibold.text-gray-500.uppercase.text-xs.pt-2 Expenses Total

            div
              v-icon( large ) mdi-cash-multiple

          .d-flex.justify-between
            .font-semibold.text-gray-500.uppercase.text-xs.mt-12 Expense Type
            .font-semibold.text-gray-500.uppercase.text-xs.mt-12 Amount
          v-divider.mt-4

          v-simple-table( v-if="!expensesPending" )
            template( v-slot:default )
              tbody
                tr(
                  v-for="expense in top3Expenses"
                )
                  td( class="w-auto lg:w-1/3 pl-0" ) {{ expense.type }}
                  td( class="w-auto text-right border-r" )
                    span.inline-block.mr-4.text-gray-500 {{ expense.count }}
                  td( class="w-auto lg:w-1 text-right" )
                    span {{ expense.amount | toCurrency }}
                tr(
                  v-if="top3Expenses.length === 0"
                )
                  td
                    .text-center.text-gray-500.py-4 No Expenses
</template>

<script>
import { computed } from '@vue/composition-api'
import useApiFind from '@/api/useApiFind'
import useAggregates from '@/api/useAggregates'

export default {
  name: 'CommitteeAdminDashboard',

  setup(props, { root: { $FeathersVuex } }) {
    const { Receipt, Expense } = $FeathersVuex.api;

    const { items: top3Expenses, isPending: expensesPending } = useApiFind({
      model: Expense,
      params: {
        query: {
          $modify: ['byExpenseType'],
          $limit: 3
        }
      }
    })

    const { items: top3Receipts, isPending: receiptsPending } = useApiFind({
      model: Receipt,
      params: {
        query: {
          $modify: ['byDonor'],
          $limit: 3
        }
      }
    })

    const { aggregates, isPending } = useAggregates({
      immediate: true,
      aggregateModels: [
        { type: 'sum', col: 'amount', service: 'Expenses' },
        { type: 'sum', col: 'amount', service: 'Receipts' }
      ]
    });
    const balance = computed(() => {
      const values = aggregates.value;
      return values.Receipts.sum.raw - values.Expenses.sum.raw;
    });

    return {
      aggregates,
      isPending,

      top3Expenses,
      expensesPending,

      top3Receipts,
      receiptsPending,
      balance
    }
  }
}
</script>